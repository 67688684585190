import React from 'react';

import BaseLayout from './base';
import styled from 'styled-components';

const IndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IndexLayout: React.FC = ({ children }) => (
  <BaseLayout title="Home">
    <IndexContainer>{children}</IndexContainer>
  </BaseLayout>
);

export default IndexLayout;
