import React from 'react';
import { PageProps, graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import styled from 'styled-components';

import IndexLayout from '../layouts';
import { IndexQuery } from '../../types/graphql-types';
import { rhythm } from '../utils/typography';
import Social from '../components/social';

const AvatorContainer = styled.div`
  margin-right: ${rhythm(1 / 2)};
  margin-bottom: 0;
  min-width: 50;
  border-radius: 100%;
`;

const IndexPage: React.FC<PageProps<IndexQuery>> = ({ data }) => {
  const { name } = data.site.siteMetadata.author;
  return (
    <IndexLayout>
      <AvatorContainer>
        <Image fixed={data.avatar.childImageSharp.fixed} alt={name} imgStyle={{ borderRadius: '50%' }} />
      </AvatorContainer>
      <h1>{name}</h1>
      <p>Web Developer / AI Engineer</p>
      <h2>Skill</h2>
      <ul>
        <li>Typescript / Javascript</li>
        <li>Python (Tensorflow / Keras)</li>
        <li>Serverless Framework</li>
        <li>React / Gatsby</li>
      </ul>
      <h2>Work</h2>
      <ul>
        <li>
          <Link to="https://werewolf.nnshun.com">Werewolf</Link>
        </li>
        <li>
          <Link to="https://omamori-inc.com">Omamori</Link>
        </li>
      </ul>
      <h2>Recent Posts</h2>
      <ul>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <li key={node.fields.slug}>
            <small>{node.frontmatter.date}</small> <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
          </li>
        ))}
      </ul>
      <Social size={rhythm(2)} />
    </IndexLayout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query Index {
    avatar: file(relativePath: { eq: "images/profile2.jpg" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author {
          name
        }
      }
    }
    allMarkdownRemark(limit: 5, sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MM/DD/YYYY")
          }
        }
      }
    }
  }
`;
